.auth-textfield__label {
    color: rgba(196, 196, 196, 1) !important;
}

.auth-textfield__input {
    color: rgba(196, 196, 196, 1) !important;
    background-color: rgba(27, 27, 27, 1) !important;
    
    /*width: 317px;*/
    /*-webkit-box-shadow: 0 0 0 1000px white inset;*/
}



.customTextField .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    /*border-color: red;*/
}

.customTextField .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(196, 196, 196, 1);
}
