.pop-up-modal {
    width: 100svw;
    height: 100svh;
    box-sizing: border-box;
    backdrop-filter: blur(5px);
    background: rgba(24, 23, 23, 0.66);
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;

    z-index: 10;


}

.pop-up-modal.active {
    opacity: 1;
    pointer-events: all;
}

.pop-up-modal__content {
    /*z-index: 100;*/

    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background: rgba(11, 11, 11, 0.85);
    width: 80svw;

    transform: scale(0.5);
    transition: 0.4s all;

    & > div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        h3 {
            color: rgb(179, 187, 200);
            font-family: Inter, serif;
            font-size: 28px;
            font-weight: 600;
            line-height: 34px;
        }

        img {
            cursor: pointer;
        }
    }
}

.pop-up-modal__content.active {
    transform: scale(1);
}