.homepage {
    background: rgb(10, 10, 10);
    min-height: 100vh;
    padding-top: 50px;

    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    row-gap: 20px;
}