.Button{
    width: 248px;
    height: 40px;
    background-color: #1B1B1B;
    border: 0;
    border-radius: 10px;
    color: #F6F6F6;
    font-size: 18px;
    font-family: Inter, serif;
}

/* br tablet */
@media (max-width: 1270px) {
    .Button {
        font-size: 16px;
        width: 220px;
    }
}