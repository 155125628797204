.weather_stations_item {
    width: 95%;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: space-around;
}

.station_info {
   display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;

    & > p {
        margin: 0;
        color: rgb(255, 255, 255);
        font-family: Inter, serif;
        font-size: 22px;
        font-weight: 600;
        line-height: 27px;
        text-align: center;
    }

    & > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 5px;
        & > h4 {
            margin: 0;
            color: rgb(164, 164, 164);
            font-family: Inter, serif;
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
            text-align: center;
        }
        & > h5 {
            margin: 0;
            color: rgb(202, 197, 197);
            font-family: Inter, serif;
            font-size: 18px;
            font-weight: 600;
            line-height: 22px;
            text-align: center;
        }
    }

    .open_map_button {
        color: rgb(164, 164, 164);
        font-family: Inter, serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        text-align: center;

        width: fit-content;
        border: none;
        background: none;
        cursor: pointer;
        padding: 0;
        margin: 20px 0 0;
    }
}

.battery_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > p {
        margin: 0;
        color: rgb(202, 199, 199);
        font-family: Inter, serif;
        font-size: 19px;
        font-weight: 600;
        line-height: 23px;
        text-align: center;
    }

    .battery_item {
        width: 105px;
        height: 39px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;


        margin-top: 32px;
        & > p {
            margin: 0;
            color: rgb(202, 199, 199);
            font-family: Inter, serif;
            font-size: 19px;
            font-weight: 600;
            line-height: 23px;
            text-align: center;
        }
    }
}

.last_data_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > p {
        color: rgb(202, 199, 199);
        font-family: Inter, serif;
        font-size: 19px;
        font-weight: 600;
        line-height: 23px;
        text-align: center
    }
}

.last_data {
    display: flex;
    column-gap: 50px;


    .section {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        & > div {
            display: flex;
            column-gap: 20px;
        }
    }
    p {
        width: fit-content;
        margin: 0;
        color: rgb(188, 188, 188);
        font-family: Inter, serif;
        font-size: 17px;
        font-weight: 500;
        line-height: 21px;
        text-align: left;
        vertical-align: middle;

        &:first-child {
            width: 140px;
        }
    }
}

/* br tablet */
@media (max-width: 1270px) {
    .last_data {
        flex-direction: column;
        row-gap: 16px;

        p {
            font-size: 16px;
        }
    }
}

/* br mobile */
@media (max-width: 767px) {
    .battery_info {
        .battery_item {
            margin-top: 18px;
        }
    }
}

@media (max-width: 460px) {
    .weather_stations_item {
        flex-direction: column;
        justify-content: center;
    }

    .station_info {
        flex-wrap: wrap;
        gap: 16px;

        & > p:first-child {
            align-self: center;
        }
    }

    .battery_info {
        .battery_item {
            align-self: flex-start;
        }

    }
}