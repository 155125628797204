.dropdown_menu {
    box-sizing: border-box;
    border-radius: 18px;
    width: fit-content;
    display: flex;
    column-gap: 10px;
    padding: 4px 2px 4px 10px;

    backdrop-filter: blur(30px);
    background: radial-gradient(23398.91% 131.24% at 0% 100%,rgba(255, 255, 255, 0),rgba(89, 87, 87, 0.09) 0.01%,rgba(255, 255, 255, 0.09) 60.507%,rgba(117, 116, 116, 0.09) 100%)

}

.dropdown-menu.show {
    padding: 8px 5px;
}


.dropdown-item:hover {
    background-color: rgb(231, 231, 231);

    & > button {
        background-color: rgb(231, 231, 231);
    }
}



.dropdown-item:active {
    --bs-dropdown-link-active-bg: rgb(178, 175, 175);

    & > button {
        width: 100%;
    }
}

.dropdown-item > button {
    width: 100%;
    background-color: white;
    border: none;
    display: flex;
    column-gap: 10px;
    justify-content: space-around;
    align-items: center;
    /*background-color: rgba(103, 108, 108, 0.7);*/

    /*&:active {*/
    /*    background-color: rgb(178, 175, 175);*/
    /*}*/
}

.dropdown-item > button > h4 {
    font-size: 18px;
    margin: 0.5rem 0;
}

