.Log-in {

  padding: 4svw;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 35px;
  box-sizing: border-box;
  border-radius: 18px;
  justify-items: center;
  box-shadow: inset 0 4px 30px 0 rgba(0, 0, 0, 0.25);
  background: radial-gradient(
          9338.59% 93.78% at 70% -9%,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.09) 0.01%,
          rgba(255, 255, 255, 0.04) 24.993%,
          rgba(255, 255, 255, 0) 100%
  );
}

.Log-in__inputs {
  display: flex;
  flex-direction: column;
  row-gap: 35px;
  justify-content: center;
  align-items: center;

  & > div {
    display: flex;
    justify-content: space-between;
  }
}

.Title {
  margin: 0;
  color: rgb(234, 234, 234);
  font-family: Inter, serif;
  font-size: 28px;
}

.Remember-me-button {
  width: 17px;
  height: 16px;
  background-color: #2a2a2a;
  border: 0;
  border-radius: 4px;
}

.forgot-password-btn {
  height: 56px;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #808080;
  font-size: 12px;

  * {
    margin: 0;
  }
}

.Remember-me-block {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Initials{
  display: flex;
  justify-content: space-between;
  width: 317px;
}

.InputName{
  width: 145px;
  height: 44px;
  background-color: rgba(27, 27, 27, 1);
  border: 0;
  border-radius: 10px;
  color: rgba(196, 196, 196, 1);
}

/* br tablet */
@media (max-width: 1270px) {
  .Log-in {
    row-gap: 24px;
  }

  .Title {
    font-size: 24px;
  }

  .Log-in__inputs {
    row-gap: 24px;

    & > div {
      scale: 90%;
    }
  }
}

/* br mobile */
@media (max-width: 767px) {

}