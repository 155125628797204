.weather_stations {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;

    box-sizing: border-box;
    border-radius: 18px;
    width: 95%;
    margin-top: 100px;
    padding: 10px 0 2rem 0;
    box-shadow: inset 0 4px 30px 0 rgba(0, 0, 0, 0.25);
    background: radial-gradient(11106.25% 93.78% at 70% -9%,rgba(255, 255, 255, 0),rgba(255, 255, 255, 0.09) 0.01%,rgba(255, 255, 255, 0.04) 24.993%,rgba(255, 255, 255, 0) 100%);
}

.weather-station-block {
    width: 100%;
    display: flex;
    row-gap: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.table_header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;

    & > h4 {
        margin: 0;
        color: rgb(255, 255, 255);
        font-family: Inter, serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        text-align: center;
    }
}

.add_weather_station_button {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    padding: 10px 20px;
    box-sizing: border-box;
    border-radius: 18px;

    backdrop-filter: blur(10px);
    background: radial-gradient(14575.01% 70.01% at 9% 145%,rgba(255, 255, 255, 0),rgba(255, 255, 255, 0.09) 0.01%,rgba(255, 255, 255, 0.09) 59.375%,rgba(255, 255, 255, 0.09) 100%);

    & > h4 {
        margin: 0;
        color: rgb(255, 255, 255);
        font-family: Inter, serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        text-align: center;
    }
}

.short_line {
    width: 65%;
    border: 1px solid rgb(71, 66, 66);
}

.long_line {
    width: 90%;
    border: 1px solid rgb(71, 66, 66);
}

/* br tablet */
@media (max-width: 1270px) {
}

/* br mobile */
@media (max-width: 767px) {
    .add_weather_station_button {
        h4 {
            display: none;
        }
    }
}

@media (max-width: 460px) {
    .table_header {

        & > h4 {
            font-size: 20px;
        }

    }
}