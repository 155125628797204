.add-weather-station {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    align-items: center;
}

.step-one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > h4 {
        margin-bottom: 56px !important;
    }
    & > h5 {
        margin-bottom: 40px !important;
    }

}

.step-two {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > h4 {
        margin-bottom: 30px !important;
    }
}

.step-three {
    /*padding: 0 250px;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > h4 {
        margin-bottom: 50px !important;
        margin-top: 60px !important;
    }

    input {
        width: 428px;
        height: 67px;
        padding-left: 14px;
        margin-bottom: 120px;

        border-radius: 10px;
        border: none;
        outline: none;
        background: rgb(27, 27, 27);
    }
    input:focus {
        border: none;
        outline: none;
    }

    input[placeholder] {
        color: rgb(119, 119, 119);
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
    }

}


.next-step {
    width: fit-content;
    margin-bottom: 10px;
    padding: 20px 80px 20px 80px;

    border-radius: 10px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.22);
    background: rgb(23, 23, 23);

    display: flex;
    column-gap: 20px;
    align-items: center;
    justify-content: center;
}

.step-info {
    display: flex;
}

.myOtp {
    width: 70%;
    margin-bottom: 34px;
    input {
        color: rgb(205, 205, 205) !important;
        font-family: Inter, serif;
        font-size: 55px;
        font-weight: 500;
        background: rgba(23, 23, 23, 0.44);
        border-radius: 10px;

    }

}

.myOtp .MuiInputBase-root {
    border: 2px solid rgb(118, 118, 118);
    border-radius: 10px;

    &:focus {
        border: none;
    }
}

.myOtp .Mui-focused {
    outline: none;
    border: none;
}

.add-weather-station, .step-one {
    h4 {
        margin: 0;
        padding: 0;
        color: rgb(235, 235, 235);
        font-family: Inter, serif;
        font-size: 28px;
        font-weight: 600;
        line-height: 34px;
    }

    h5 {
        margin: 0;
        padding: 0;
        width: 45%;
        color: rgb(146, 146, 146);
        font-family: Inter, serif;
        font-size: 17px;
        font-weight: 600;
        line-height: 21px;
        text-align: center;
    }
}
