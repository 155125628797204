.authentication-page {
  padding: 10svh 0;
  width: 100%;
  min-height: 100svh;
  position: relative;
  background-color: #0a0a0a;

  .auth {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-evenly;
  }
}