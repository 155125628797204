.navigation {
    display: flex;
    padding: 0 50px;
    justify-content: space-between;
    width: 100%;
}

.greeting {
    display: flex;
    align-items: center;

    box-sizing: border-box;
    border-radius: 18px;
    padding: 0 15px;

    backdrop-filter: blur(30px);
    background: radial-gradient(21655.62% 127.44% at 12% 100%,rgba(255, 255, 255, 0),rgba(102, 98, 98, 0.09) 0.01%,rgba(255, 255, 255, 0.09) 57.379%,rgba(99, 99, 99, 0.09) 100%);
}

.greeting > p {
    margin: 0;
    color: rgb(255, 255, 255);
    font-family: Inter, serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-align: center;
}


/* br tablet */
@media (max-width: 1270px) {

}

/* br mobile */
@media (max-width: 767px) {


}

@media (max-width: 460px) {
    .navigation {
        padding: 0 24px;
    }

}